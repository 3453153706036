import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { CategoryModule } from '@/store/category';
import Browser from '@/support/browser';
import Globals from '@/globals';
import PlayerApi from '@/support/playerApi';
import PlayerUtil from '@/support/playerUtil';
import { ServerDataModule } from '@/store/serverData';
import Util from '@/support/utility';
import { IWizardManager, ICategoryDto } from '@/interfaces';
import { EventBus } from '@/support/eventBus';

@Component
export default class AddNewCategoryComponent extends Vue {

    options = {
    };

    model = Globals.NewCategory;

    busy = false;
    key = 'addNewCategory';
    errors: string[] = [];

    @Prop({ default: null })
    wizard: IWizardManager;

    @Prop({ default: <any>300 })
    height: string;

    get editMode() {
        return this.model.id > 0;
    }

    get saveDisabled() {
        return this.busy || !this.model.name || !Util.isValidName(this.model.name);
    }

    created() {
        Debug.setDebugModule('addNewCategory', this);
    }

    mounted() {
        Debug.log('addNewCategory mounted');
    }

    showExpired() {
        this.done();
    }

    show(category: SUR.CategoryDto = null) {
        Debug.log('addNewCategory show', category);

        category = category || Globals.NewCategory;

        if (ServerDataModule.serverData.debugging && category.id == 0) {
            category.name = 'DeleteMe' + Util.getRandomInt(1000);
        }

        Object.assign(this.model, category);

        this.busy = false;
        (<any>this.$modal).show(this.key);
    }

    hide() {
        (<any>this.$modal).hide(this.key);
    }

    validateInput() {
        this.errors.splice(0, this.errors.length);

        if (!this.model.name)
            this.errors.push('Name required');
        else if (!Util.isValidName(this.model.name))
            this.errors.push('Invalid name');

        return !this.errors.length;
    }

    checkAlreadyExists() {
        let name = Util.sanitizeName(this.model.name);
        let found = CategoryModule.categories.find(item => item.name == name);
        return found && found.id != this.model.id;
    }

    async done() {
        if (!this.validateInput()) return false;

        this.busy = true;

        try {

            if (this.checkAlreadyExists()) {
                Util.showError('Category already exists');
                return null;
            }

            if (!this.editMode) {
                let orders = CategoryModule.categories.map(item => item.order);

                // Remove Archived Portals from orders (always last)
                let idx = orders.indexOf(1000);
                if (idx != -1) {
                    orders.splice(idx, 1);
                }

                let maxOrder = Math.max(...orders) || 1;

                Debug.log('addNewCategory new category order', maxOrder + 1, orders);
                this.model.order = maxOrder + 1;
            }

            let response = null;
            if (this.editMode) {
                response = await CategoryModule.editCategory(this.model);
                Util.showToast('Updated category ' + this.model.name);
            }
            else {
                response = await CategoryModule.createCategory(this.model);
                Util.showToast('Added new category ' + this.model.name);
            }

            Debug.log('addNewCategory done SUCCEEDED');

            EventBus.Instance.$emit(EventBus.CategoriesChanged, response);

            this.hide();

            return null;
        } catch (err) {
            let message = err.message || 'ERROR';
            Debug.error("addNewCategory FAILED ", this.model.name, message);
            Util.showError('Failed to add new category ' + this.model.name);
            this.busy = false;
            return null;
        } finally {
            this.busy = false;
            return null;
        }
    }

    cancel() {
        this.hide();
    }

}
